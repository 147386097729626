.data__table{
    font-size: .7rem;
}

.add__btn{
    display: flex;
    align-items: center;
    border: none;
    width: 100%;
}
.add__btn a{
    text-decoration: none;
    color: #fff;
}

@media only screen and (max-width: 500px) {
    .onlyOnPC{
        display: none;
    }
}