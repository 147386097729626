.add__product h1{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.add__form{
    margin-left: 40px;
}

.add__data{
    border: 1px solid #000000a0;
    padding: 7px 12px;
    align-items: center;
    border-radius: 50px;
    margin-bottom: 3px;
    margin-top: -12px;
    background: #fff;
    width: 250px;
    display: flex;
    align-items: center;
}

.add__data input{
    border: none;
    outline: none;
    background: transparent;
}

.nombre, .nombre input{
    width: 35rem;
}

.descripcion{
    width: 37.5rem;
    height: 18rem;
}
.descripcion textarea{
    width: 35rem;
    border: none;
    margin-left: 7px;
    background: transparent;
}
.descripcion textarea :hover{
    background: transparent;
    color: transparent;
}

.precio, .precio input{
    width: 100px;
}

.imagen, .imagen input{
    width: 30rem;
}

.insert__btn{
    margin-top: 15px;
}

.add__portada{
    display: flex;
}
.add__portada p{
    margin-left: 20px;
    font-weight: 600;
    color: blue;
}

.add__finish{
    display: flex;
    align-items: center;
    justify-self: center;
}

@media only screen and (max-width: 450px) {

    .add__form{
        margin-left: 4px;
    }
    .nombre, .nombre input{
        width: 23rem;
    }
    .descripcion{
        width: 23rem;
        height: 8rem;
    }
    .descripcion textarea{
        width: 20rem;
        height: 6.5rem;
    }

    .imagen{
        width: 22rem;
    }
    .imagen input{
        width: 20rem;
    }
}