.car__item {
    border: 3px solid #7c8a9736;
    padding: 20px;
    border-radius: 5px;
    width: 20rem;
}
  
.car__item-info span i {
   color: #f92626;
   font-size: 1.1rem;
}

.car__img img{
  max-height: 300px;
}
  
.car__item-btn {
  border-radius: 0;
  border: none;
  outline: none;
  padding: 8px 0px;
  text-align: center;
}
  

.car__btn-details {
  background: #f92626b6;
  border-radius: 4px;
  align-items: center;
  color: #fff;
}

.car__btn-details:hover{
  color: rgba(255, 255, 255, 0.456);
}
  
  
.car__item-content h4 {
  font-size: 1.3rem;
}

.car__item-content h6 {
  font-size: 1rem;
  font-weight: 600;
}

.car__item-info span{
  font-size: .8rem;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .car__item h4 {
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  .car__item h6 {
    font-size: 1rem;
    margin-top: 10px;
  }

  .car__item-info {
    flex-wrap: wrap;
  }

  .car__item-info span {
    font-size: 0.8rem;
    justify-content: space-between;
  }

  .car__item-btn {
    padding: 5px 10px;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 768px) {
  .car__item h4 {
    font-size: 1rem;
  }

  .car__item h6 {
    font-size: 0.9rem;
  }

  .car__item-info span {
    font-size: 0.7rem;
  }

  .car__item-info span i {
    font-size: 0.9rem;
  }

  .car__item-btn {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 500px) {
  .car__item{
    width: 250px;
  }
}