.btn__top button{
    color: rgb(255, 255, 255, 0.727);
    border: rgba(255, 255, 255, 0.727) solid;
    border-radius: 10px;
    background: transparent;
    display: block;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    padding: 0px 20rem;
    transform: translate( 8rem,-30px);
}

.btn__top button:hover{
    color: rgba(255, 255, 255, 0.394);
    transition: 0.1s;
}

@media only screen and  (max-width: 768px){
    .btn__top button{
        font-size: 1.3rem;
        padding: 0px 10rem;
        transform: translate( 1rem,-30px);
    }
}

@media only screen and  (max-width: 540px){
    .btn__top button{
        font-size: 1.5rem;
        padding: 0px 5rem;
        transform: translate( 4rem,-30px);
    }
}

@media only screen and  (max-width: 400px){
    .btn__top button{
        font-size: 1.2rem;
        padding: 0px 5rem;
        transform: translate( 3rem,-30px);
    }
}

@media only screen and  (max-width: 430px){
    .btn__top button{
        font-size: 1.2rem;
        padding: 0px 5rem;
        transform: translate( .7rem,-30px);
    }
}