@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,600;1,700&display=swap');

/*General Style*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Sans 3', sans-serif;;
};

/*Pre-define Style*/

h1, h2, h3, h4, h5{
  color: #000d6b;
  font-weight: 600;
  margin-bottom: 0;
}

h3, h4{
  font-size: 1.8rem;
}

h5, h6{
  font-size: 1.2rem;
}

a{
  text-decoration: none;
  color: unset;
}

a:hover{
  color: unset;
}

ul{
  margin: 0;
  list-style: none;
}

.section__title{
  color: #000d6b;
  font-weight: 600;
  font-size: 2rem;
}

.section__subtitle{
  color: #f9a826;
  font-weight: 600;
  font-style: 1.2rem;
}

.section__description{
  color: #7c8a97;
  font-style: 0.9em;
  line-height: 30px;
}

section{
  padding: 50px 0px;
}

.btn{
  padding: 7px 15px;
  background: #000d6b;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
}
.btn:hover{
  color: #fff;
  background: #000d6b;
}

@media only screen and (max-width:768px){
  
  section{
    padding: 30px 0px;
  }
  .section__title{
    font-style: 1.6rem;
  }
  .section__subtitle{
    font-style: 1.1rem;
  }
  
}