.single__item-back{
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
    url('../assets/Images/ImagesSlider/Slider02.jpg');
}
.single__item{
    
    box-shadow: 0px 5px 15px 5px #7c8a97;
    margin: 100px;
    margin-left: 160px;
    width: 60rem;
    height: 30rem;
}

.single__item-title{
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 15px;
}
.section__title{
    font-family: 'Times New Roman', Times, serif;
}

.single__item img{
    width: 30rem;
    max-height: 300px;
    padding: 0px 45px;
    border-radius: 15px;
}

.single__item-info i{
    font-weight: 600;
    font-size: 1.1rem;  
}
.single__item-content{
    margin-top: 20px;
}

.single__item-btn{
    border: none;
    color: #fff;
    width: 160px;
    height: 50px;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 3px;
}

.wpp__btn{
    background: #42E860;
}
.call__btn{
    background: #2692FC;
    margin-left: 5px;
}

@media only screen and (max-width: 768px) {
    
    .single__item{
        width: 30rem;
        height: 42rem;
        margin: 50px;
        margin-left: 120px;
    }
    .section__title{
        font-size: 1.5rem;
    }
    .single__item-info{
        font-size: .7rem;
    }
    .single__item img{
        max-height: 200px;
        padding: 0px 60px;
    }
}

@media only screen and (max-width: 435px) {

    .single__item{
        width: 20rem;
        margin: 40px;
        margin-left: 40px;
    }
    .section__title{
        font-size: 1.3rem;
    }
    .single__item img{
        max-height: 200px;
        padding: 0px 20px;
    }
    .single__item-btn{
        width: 140px;
        font-size: 1rem;
    }
}
  