.footer{
    background: #041185e9;
    color: rgba(255, 255, 255, 0.715);
    padding-top: 50px;
    padding-bottom: 15px;
}

.footer__link-tittle{
    color: #fff;
}

.footer__logo-content{

    margin-top: 15px;
    line-height: 30px;
}

.social__media button{
    font-size: 2rem;
    background: transparent;
    border: none;
    margin-left: 5px;
}
.social__media button a{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.715);
}
.social__media button a:hover{
    color: rgba(255, 255, 255, 0.364);
    transition: .1s;
    font-weight: 300;
}

.footer__links{
    border: none !important;
    background: transparent !important;
}
.footer__links a{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.715);
}
.footer__links a:hover{
    color: rgba(255, 255, 255, 0.364);
    transition: .1s;
    font-weight: 300;
}


@media only screen and (max-width: 762px) {
    .footer__logo-content{
        font-size: 1rem;
    }
    .footer__links{
        font-size: 1rem;
    }
    .office__info{
        font-size: 1rem;
    }
}