.login{
    padding-bottom: 30px;
    padding: 7px 12px;
    color: #fff;
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
    url('../assets/Images/Login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login__form{
    align-items: center;
    padding: 10px 50px;
}

.login__data{
    border: 1px solid #7c8a972f;
    padding: 7px 12px;
    align-items: center;
    border-radius: 50px;
    margin-bottom: 3px;
    margin-top: -12px;
    background: #fff;
    width: 250px;
    display: flex;
    align-items: center;
}
.login__data input{
    
    border: none;
    width: 250px;
    outline: none;
    background: transparent;
}

.login__title h6{
    padding-top: 20px;
    font-size: 2rem;
    font-weight: 600;
}

.login__btn{
    margin-top: 20px;
    border: none;
    border-radius: 30px;
    width: 100px;
    height: 30px;
    background: #041185e9;
    margin-bottom: 50px;
    text-decoration: none;
    color: #fff;
}
.login__btn :hover{
    color: rgba(255, 255, 255, 0.441);
    transition: .1s;
}