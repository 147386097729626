/*HEADER TOP*/

.header__top{
    padding: 10px 0px;
    background: #041185e9;
    color: #fff;
    
}

/*HEADER TOP LEFT*/
.header__top__left{
    display: flex;
    align-items: center;
    column-gap: 1rem;
}
.header__top__left span{
    font-size: 0.9rem;
}

.header__top__help{
    display: flex;
    align-items: center;
    column-gap: .4rem;
}
.header__top__help i{
    background: #fff;
    color: #041185e9;
    padding: 2px 4px;
    border-radius: 50%;
    font-size: .8rem;
}

/*HEADER TOP RIGHT*/

.header__top__right a{
    text-decoration: none;
    color: #fff;
    font-size: 0.9rem;
}
.header__top__right a:hover{
    color: rgba(255, 255, 255, 0.475) !important;
    transition: 0.1s;

}

/*HEADER MIDDLE*/

.header__horario{
    margin-top: 20px;
}

.header__top__middle{
    padding: 20px 0px;
    border-style: solid;
    border-color: #041185e9;
    border-width: 4px;
    background: #fff;
    
}

.Logo h1 a span{
    font-size: 1.5rem;
    
}
.Logo h1 a img{
    font-size: 2.2rem;
    color: #fff;
    margin-top: 10px;
}
.Logo h1 a{
    text-decoration: none;
    color: #041185e9;
    font-weight: 700;
}
.Logo h1{
    line-height: 1.5rem;
}

.Logo-Car{
    width: 20rem;
    height: 5rem;
}

.header__location i{
    font-size: 2.2rem;
    color: #041185e9;
}
.header__location__content h4{
    font-size: 1.2rem;
    font-weight: 700;
    color: #041185e9;
}
.header__location__content h6{
    font-weight: 500;
    
}

/*HEADER BUTTONS*/
.header__btns a{
    padding: 10px 20px;
    display: flex;
    justify-content: right;
    align-items: center;
    column-gap: .4rem;
    text-decoration: none;
    color: #041185e9 !important;
    /*justify-content: end;*/
    font-size: 1rem;
    font-weight: 600;
    width: 12rem;
    margin-top: 6px;
    margin-left: -50px;
}
.header__btns a:hover{
    color: #0e27e1b9;
    transition: 0.1s;
}
.header__btns i{
    font-size: 1.3rem;
}

/*MAIN NAVIGATION*/

.main__navbar{
    padding: 15px 0px;
    background: #041185e9;
}

.mobile__menu i{
    color: #fff !important;
    font-size: 1.3rem;
    cursor: pointer;
}

.mobile__menu{
    display: none;
}

.menu{
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
}

.nav__item{
    color: #fff;
    text-decoration: none;
    transition: .3s;
}

.nav__item:hover{
    color: rgb(246, 28, 28);

}

.search{
    border: 1px solid #7c8a972f;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    background: #041185e9;
}
.search input{
    background: transparent;
    border: none;
    outline: none;
    color: rgba(255, 255, 255, 0.87);
}
.search input::placeholder{
    font-size: 0.8rem;
}

.nav__right span{
    color: #fff;
    padding: 0px 5px;
    cursor: pointer;
}

.nav__active{
    color: #F8C801; 
}

/*MOBILE STYLE HEADER*/
@media only screen and (max-width: 768px) {

    .header__top__middle, .nav__right {
        display: none;
    }

    .header__top{
        margin-bottom: -45px;
        background: transparent;
    }

    .mobile__menu{
        display: block;
    }

    .navigation{
        background: rgb(0, 13, 107, 0.6);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        display: none;
    }

    .menu__active{
        display: block;
    }
    
    .menu{
        width: 250px;
        height: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 2rem;
        justify-content: center;
    }

    .menu a{
        color: #0e27e1b9;
        font-weight: 600;
        font-size: 1rem;
    }
}