.about__section-content{
    margin-top: 70px;
}

.section__title{
    color: rgb(246, 28, 28);
}

.about__section-item{
    column-gap: 3rem;
    display: flex;
    align-items: center;
}
.about__section-item p i{
    color: #F8C801;
    font-size: 1.1rem;
}
.about__section-item h5{
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    margin-top: 30px;
}

.section__description{
    color: rgba(0, 0, 0, 0.6);
}

.about__section-content h4{
    color: #F8C801;
}

@media only screen and (max-width: 992px) {
    .about__section-content h2{
        font-size: 1.5rem;
    }
    .about__section-item{
        column-gap: 2.7rem;
    }
    .about__section-item p{
        font-size: 1rem;
    }
}