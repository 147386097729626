.hero__slider{
    overflow: hidden;
}

.slider__item{
    max-width: 100%;
    height: 600px;
}

.slider__content{
    padding: 150px;
}
.slider__content h1{
    font-size: 3.2rem;
    font-weight: 600;
}
/*SLIDERS*/

.slider__item-01{
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
    url('../assets/Images/ImagesSlider/Slider01.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slider__item-02{
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
    url('../assets/Images/ImagesSlider/Slider02.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slider__item-03{
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
    url('../assets/Images/ImagesSlider/Slider03.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.reserve__btn{
    background: #fff !important;
    color: #041185e9;
    border: none;
    outline: none;

}
.reserve__btn a{
    text-decoration: none;
    font-weight: 600;
    color: #041185e9;
}
.reserve__btn a:hover{
    color:red;
}

/*MOBILE SLIDER STYLE*/
@media only screen and (max-width: 992px) {
  .slider__item {
    height: 530px;
    max-width: 100vw;
  }

  .slider__content {
    margin-top: -50px;
    margin-left: -70px;
  }

  .slider__content h1 {
    font-size: 2rem;
  }
}
  
@media only screen and (max-width: 768px) {
  
  .slider__item {
    height: 450px;
  }

  .slider__content h1 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 450px) {
  .slider__content h1 {
    font-size: 1rem;
    width: 200px;
  }

  .slider__item {
    height: 400px;
  }
}
