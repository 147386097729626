
.find__car-left{
    background: linear-gradient(rgba(249, 168, 38, 0.8), rgb(249, 168, 38, 0.8)),
    url('../assets/Images/findCarImage.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 30px;
    color: #fff;
    width: 25rem;
    height: 15rem;
}
.find__car-left h1{
    font-size: 3rem;
    margin-top: 10px;
}

.form__group{
    width: 30%;
    margin-bottom: 0;
}
.form__group input{
    border: 1px solid;
    padding: 8px 12px;
    outline: none;
    width: 100%;
    outline: none;
}

/*.form{
    background: rgb(233, 8, 8);
    padding-top: 55px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 10px;
}*/

.form__row{
    background: #fff;
    box-shadow: 5px 5px 15px -5px #7c8a97;
    
}

.hero__slider-section{
    position: relative;
    z-index: 10;
    margin-bottom: 200px;
}
.hero__form{
    position: absolute;
    bottom: -150px;
    z-index: 11;
    width: 100%;
}

/*MOBILE Acq STYLE*/

  
@media only screen and (max-width: 768px) {
  .find__car-left {
    padding: 65px 22px;
  }

  .find__car-left h1{
    font-size: 3rem;
    width: 320px;
    margin-right: 20px ;
  }

  .form {
    padding: 60px 10px;
  }

  .form__group input, .select__group select {
    padding: 7px 10px;
    font-size: 0.8rem;
  }

  .hero__form {
    bottom: -250px;
  }
  
  .find__car-left {
    padding-top: 20px;
    padding-bottom: 0;
    /*background: #fff;*/
    text-align: center;
    width: 100%;
    height: 15rem;
  }
  
  .form {
    padding: 20px 10px;
  }
}
 
@media only screen and (max-width: 576px) {
   
  .find__car-left h1 {
    font-size: 2.3rem;
    margin-top: 50px;

    margin-left: -7px;
  }

  .find__car-left {
    width: 351.5px;
    height: 15rem;

  }
  
  .hero__form {
    bottom: -250px;
  }
}